export const Slide1 = require('images/main/Slide1.webp');
export const Slide2 = require('images/main/Slide2.webp');
export const Slide3 = require('images/main/Slide3.webp');
export const Slide4 = require('images/main/Slide4.webp');
export const Slide5 = require('images/main/Slide5.webp');
export const AboutUs = require('images/main/AboutUs.webp');
export const News = require('images/main/News.webp');
export const Expertise = require('images/main/Expertise.webp');
export const Projects = require('images/main/Projects.webp');
export const Careers = require('images/main/Careers.webp');
export const ContactUs = require('images/main/ContactUs.webp');
export const BgImage = require('images/main/BgImage.webp');

export const ConstructionGraphBg = require('images/main/ConstructionGraphBg.webp');
export const WirelessGraphBg = require('images/main/WirelessGraphBg.webp');
export const EnergyGraphBg = require('images/main/EnergyGraphBg.webp');
export const EngineeringGraphBg = require('images/main/EngineeringGraphBg.webp');
export const DeploymentGraphBg = require('images/main/DeploymentGraphBg.webp');
export const Energy2GraphBg = require('images/main/Energy2GraphBg.webp');
export const ConsultingGraphBg = require('images/main/ConsultingGraphBg.webp');
export const DataGraphBg = require('images/main/DataGraphBg.webp');
export const WirelineGraphBg = require('images/main/WirelineGraphBg.webp');
export const FulfillmentGraphBg = require('images/main/FulfillmentGraphBg.webp');
export const OilGraphBg = require('images/main/OilGraphBg.webp');
export const WaterGraphBg = require('images/main/WaterGraphBgo.webp');
export const PowerDeliveryGraphBg = require('images/main/PowerDeliveryGraphBg.webp');

export const ConsultingBusinessHomePage = require('images/main/ConsultingBusinessHomePage.webp');
export const DataCentersHomePage = require('images/main/DataCentersHomePage.webp');
export const FulfillmentHomePage = require('images/main/FulfillmentHomePage.webp');
export const OilGasHomePage = require('images/main/OilGasHomePage.webp');
export const PowerDeliveryHomePage = require('images/main/PowerDeliveryHomePage.webp');
export const RenewableEnergyHomePage = require('images/main/RenewableEnergyHomePage.webp');
export const WaterUtilityHomePage = require('images/main/WaterUtilityHomePage.webp');
export const WirelineHomePage = require('images/main/WirelineHomePage.webp');
