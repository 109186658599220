import { FC, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Box, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { SliderArrows } from 'components/atoms';
import { ContentSlider } from 'components/structures/main';
import { ContentSliderProps } from 'components/structures/main/ContentSlider';

type SlideProps = ContentSliderProps;

interface SliderProps {
  sliderList: Array<SlideProps>;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    slider: {
      marginTop: 0,
      [theme.breakpoints.up('md')]: {
        transform: 'translateX(3px)',
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 80,
        transform: 'translateX(3px)',
      },
    },
    slideWrapper: {
      padding: 0,
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(0.6),
      },
    },
  });
});

const MainSlider: FC<SliderProps> = ({ sliderList }) => {
  const [slide, setSlide] = useState(0);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const arrowHandlers = {
    onPrev: null,
    onNext: null,
  };

  const handleChange = (selectedIndex) => {
    if (!loading && slide !== selectedIndex) {
      setLoading(true);
      setSlide(selectedIndex);
      setTimeout(() => {
        setLoading(false);
      }, 601);
    }
  };

  useEffect(() => {
    const timerAnimation = setTimeout(() => {
      setLoading(false);
    }, 100);
    return () => {
      clearTimeout(timerAnimation);
    };
  }, []);

  return (
    <>
      <Carousel
        className={classes.slider}
        autoPlay
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        showThumbs={false}
        swipeable={false}
        interval={5000}
        onChange={handleChange}
        selectedItem={slide}
        transitionTime={1200}
        infiniteLoop
        renderArrowPrev={(onClick) => {
          arrowHandlers.onPrev = onClick;
          return null;
        }}
        renderArrowNext={(onClick) => {
          arrowHandlers.onNext = onClick;
          return null;
        }}
      >
        {sliderList.map(({ bgImage, href, title, text1, text2, text3, bgGraphic }, index) => (
          <div key={index} className={classes.slideWrapper}>
            <ContentSlider
              bgImage={bgImage}
              href={href}
              text1={text1}
              text2={text2}
              text3={text3}
              title={title}
              bgGraphic={bgGraphic}
            />
          </div>
        ))}
      </Carousel>
      <SliderArrows totalSlides={Array.from(sliderList.keys())} onClickIndicator={handleChange} currentSlide={slide} />
      <Box id={'core-values'} />
    </>
  );
};

export default MainSlider;
