import { NextPage } from 'next';
import React from 'react';
import { BusinessQualities, DeliveringIndustrySolutions, MainSlider, OurCompanyText, SiteMapSection } from 'containers/main';
import {
  ConstructionGraphBg,
  ConsultingBusinessHomePage,
  ConsultingGraphBg,
  DataCentersHomePage,
  DataGraphBg,
  DeploymentGraphBg,
  Energy2GraphBg,
  EngineeringGraphBg,
  FulfillmentGraphBg,
  FulfillmentHomePage,
  OilGasHomePage,
  OilGraphBg, PowerDeliveryGraphBg,
  PowerDeliveryHomePage,
  RenewableEnergyHomePage,
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  WaterGraphBg,
  WaterUtilityHomePage,
  WirelessGraphBg,
  WirelineGraphBg,
  WirelineHomePage,
} from 'images/main';
import {
  ConfidenceIcon,
  ExpertiseIcon,
  FlexibilityIcon,
  InnovationIcon,
  IntegrityIcon,
  QualityIcon,
  SafetyIcon,
} from 'svgs/main';

const Main: NextPage = () => {
  return (
    <>
      <MainSlider
        sliderList={[
          {
            bgImage: Slide2,
            title: 'Engineering',
            text1: 'Making any project a success.',
            text2: 'We can make any concept come to life - from scratch.',
            text3: 'Our experts are there every step of the way with both aesthetics and functionality in mind.',
            href: '/expertise/engineering',
            bgGraphic: EngineeringGraphBg,
          },

          {
            bgImage: Slide1,
            title: 'Construction',
            text1: 'Building a better tomorrow.',
            text2: 'We are committed to making a real impact.',
            text3: 'Our crew is on a mission to assemble the infrastructure your community deserves.',
            href: '/expertise/construction',
            bgGraphic: ConstructionGraphBg,
          },
          {
            bgImage: Slide3,
            title: 'Wireless',
            text1: 'Bringing people together.',
            text2: 'We have what it takes to facilitate rock-solid broadband connections.',
            text3: 'Our commitment to innovation allows us to deliver cutting-edge network solutions.',
            href: '/expertise/wireless',
            bgGraphic: WirelessGraphBg,
          },
          {
            bgImage: Slide5,
            title: 'Technology Deployment',
            text1: 'Integrating modern innovations.',
            text2: 'We use avant-garde tech in all our processes.',
            text3: 'Our employees are fully equipped to carry out the installation, maintenance, and repair activities.',
            href: '/expertise/technology-deployment/gpm',
            bgGraphic: DeploymentGraphBg,
          },
          {
            bgImage: WaterUtilityHomePage,
            title: 'Water Utility Services',
            text1: 'Connecting Communities with Reliable Water Solutions.',
            text2: 'Delivering high-quality water infrastructure services to ensure sustainable access, ',
            text3: 'resilience, and reliability for communities nationwide.',
            href: '/expertise/water-utility-services',
            bgGraphic: WaterGraphBg,
          },
          {
            bgImage: PowerDeliveryHomePage,
            title: 'Power Delivery',
            text1: 'Ensuring Uninterrupted Power Delivery to Fuel Growth.',
            text2:
              'From generation to distribution, our solutions deliver reliable, our solutions deliver reliable, uninterrupted power',
            text3: 'that fuels economic expansion and community well-being.',
            href: '/expertise/power-delivery',
            bgGraphic: PowerDeliveryGraphBg,
          },
          {
            bgImage: WirelineHomePage,
            title: 'Wireline Services',
            text1: 'Strengthening the Wireline Backbone for Seamless Connectivity.',
            text2: 'Offering comprehensive wireline solutions that support robust connectivity,',
            text3: 'seamless communication, and the backbone of modern infrastructure.',
            href: '/expertise/wireline',
            bgGraphic: WirelineGraphBg,
          },
          {
            bgImage: OilGasHomePage,
            title: 'Oil & Gas',
            text1: 'Meeting Oil and Gas Needs with Precision and Care.',
            text2: 'Providing innovative, tailored solutions for the oil and gas sector, ensuring',
            text3: 'operational efficiency, enhanced safety, and compliance.',
            href: '/expertise/oil-gas',
            bgGraphic: OilGraphBg,
          },
          {
            bgImage: RenewableEnergyHomePage,
            title: 'Renewable Energy',
            text1: 'Pioneering Renewable Energy for a Sustainable Future.',
            text2: 'Leading the way in renewable energy solutions with innovative technologies that ',
            text3: 'empower cleaner, greener communities and drive sustainable growth.',
            href: '/expertise/energy',
            bgGraphic: Energy2GraphBg,
          },
          {
            bgImage: FulfillmentHomePage,
            title: 'Fulfillment Services',
            text1: 'Comprehensive Fulfillment Services to Meet All Infrastructure Needs.',
            text2: 'Covering every need from installation to troubleshooting, our full-scale fulfillment services',
            text3: 'guarantee efficiency, continuity, and complete support for critical infrastructure.',
            href: '/expertise/fulfillment-services',
            bgGraphic: FulfillmentGraphBg,
          },
          {
            bgImage: DataCentersHomePage,
            title: 'Data Centers Capabilities',
            text1: 'Supporting the digital infrastructure of tomorrow',
            text2: 'We provide critical services for maintaining and optimizing data centers.',
            text3: ' Our team ensures seamless operations to meet the demands of a connected world.',
            href: '/expertise/data-centers-capabilities',
            bgGraphic: DataGraphBg,
          },
          {
            bgImage: ConsultingBusinessHomePage,
            title: 'Consulting & Business Services',
            text1: 'Utilities One provides specialized business and consulting services',
            text2: 'tailored to enhance operational success and regulatory compliance.',
            text3: '',
            href: '/expertise/consulting-business-services',
            bgGraphic: ConsultingGraphBg,
          },
        ]}
      />
      <DeliveringIndustrySolutions
        title={'Setting the Standard in Infrastructure Solutions'}
        description={
          'is a comprehensive service provider, specializing in advanced infrastructure solutions for Telecommunications, Electric Utilities, Oil & Gas, Water, Wireless, Engineering, and Technology Deployment sectors. Our expertise ensures innovative and reliable services across diverse industries, driving success and sustainability.'
        }
        orangeText={'Utilities One '}
      />
      <BusinessQualities
        list={[
          {
            icon: <SafetyIcon />,
            title: 'Safety',
            description:
              'Our top priority is the health and well-being of our team and customers, ensuring a safe and secure environment.',
          },
          {
            icon: <ConfidenceIcon />,
            title: 'Confidence',
            description:
              'We value our people, building strong, trustworthy connections that foster impactful business relationships.',
          },
          {
            icon: <QualityIcon />,
            title: 'Quality',
            description:
              'We pursue excellence in every project, maintaining high standards from small tasks to large-scale initiatives.',
          },
          {
            icon: <InnovationIcon />,
            title: 'Innovation',
            description:
              'We believe in modern solutions and technology, driving progress through exceptional and forward-thinking results.',
          },
          {
            icon: <IntegrityIcon />,
            title: 'Integrity',
            description:
              'We are committed to ethical conduct and transparency, building a foundation of trust and reliability in every service.',
          },
          {
            icon: <ExpertiseIcon />,
            title: 'Expertise',
            description:
              'With knowledge and experience, we handle challenges confidently, delivering proficient solutions every time.',
          },
          {
            icon: <FlexibilityIcon />,
            title: 'Flexibility',
            description:
              'By adapting to change, we guarantee efficient and flexible operations that meet diverse and evolving needs.',
          },
        ]}
      />
      <SiteMapSection />
      <OurCompanyText />
    </>
  );
};

export default Main;
